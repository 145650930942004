<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center mt-5">
      <Preloader/>
    </div>
<!--    <div v-else>-->
<!--      <BindFlows v-if="false"/>-->
<!--      <BindGroupsNew v-else/>-->
<!--    </div>-->

      <div v-for="(item, index) in scheduleDisciplinesData.scheduleDisciplines" :key="index">
        <ul v-for="(disciplines, indexDiscp) in item" :key="indexDiscp">
          {{ disciplines.day }}
        </ul>
      </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
// import BindGroupsNew from '@/components/education-programme/curriculum/BindGroupsNew.vue'
// import BindFlows from '@/components/education-programme/curriculum/BindFlows.vue'

export default {
  name: "BindToCurriculumNew",
  components: {
    // BindGroupsNew, BindFlows
  },
  data() {
    return {
      disciplineId: +this.$route.query.discipline_id || 0,
      semester: this.$route.query.semester || 0,
      academic_year: this.$route.query.academic_year || 0,
      loading: true
    }
  },
  computed: {
    ...mapState('educationCurriculum', ['scheduleDisciplinesData']),
    isFlow() {
      return this.curriculumDisciplinesData.discipline.is_thread
    }
  },
  methods: {
    ...mapActions('educationCurriculum', ['GET_DISCIPLINE_SCHEDULE']),
  },
  async mounted() {
      await this.GET_DISCIPLINE_SCHEDULE({discipline_id: this.disciplineId, semester: this.semester, academic_year: this.academic_year})
    this.loading = false
  }
}
</script>

<style scoped>

</style>